import "bootstrap/dist/css/bootstrap.css";
import { createApp, markRaw } from "vue";
import App from "./App.vue";
import "./assets/style.css";
import router from "./router.js";

import "sweetalert2/dist/sweetalert2.min.css";
import VueSweetalert2 from "vue-sweetalert2";

import { createPinia } from "pinia";
import Toast, { POSITION } from "vue-toastification";
import "vue-toastification/dist/index.css";

import axios from "axios";
import Vue3Progress from "vue3-progress";

const pinia = createPinia();
const app = createApp(App);
// Check the URL to determine which API base URLs to use
const hostname = window.location.hostname;
if (hostname === "edms.bitz-itc.com") {
    // if (hostname === "localhost") {
    app.config.globalProperties.baseApiUrl =
        "https://edms.bitz-itc.com";
    app.config.globalProperties.apiBaseUrl =
        "https://edms.bitz-itc.com:8001/api";
    axios.defaults.baseURL = "https://edms.bitz-itc.com:8001/api";
} else {
    app.config.globalProperties.apiBaseUrl = "http://127.0.0.1:8000/api";
    app.config.globalProperties.baseUrl = "http://127.0.0.1:8000";
    axios.defaults.baseURL = "http://127.0.0.1:8000/api";
    // app.config.globalProperties.baseApiUrl =
    //     "http://edms-enpoints.bitz-itc.com";
    // app.config.globalProperties.apiBaseUrl =
    //     "http://edms-enpoints.bitz-itc.com/api";
    // axios.defaults.baseURL = "http://edms-enpoints.bitz-itc.com/api";
}

pinia.use(({ store }) => {
    store.$router = markRaw(router);
});

app.mixin({
    created() {
        //   const userInfo = localStorage.getItem("user");
        //   if (userInfo) {
        //     const userData = JSON.parse(userInfo);
        //     this.$pinia.user.setUserData(userData);
        //   }

        axios.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response.status === 401) {
                    this.$pinia.auth.logout();
                }
                return Promise.reject(error);
            }
        );
    },
});

app.use(VueSweetalert2);
const toast_options = {
    // You can set your default options here
    position: POSITION.BOTTOM_RIGHT,
};
app.use(Toast, toast_options);
const progress_options = {
    position: "fixed",
    height: "8px",
    color: "#355b11",
};
app.use(Vue3Progress, progress_options);
app.use(router);
app.use(pinia);
app.mount("#app");
